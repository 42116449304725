import type { ParametersOfPartialRouteDefinition, RequiredRouteParameter, RouteTemplate, UnknownPartialRouteDefinition, UnknownRegisteredRoute } from "@octopusdeploy/portal-routes";
import { isRouteParameter, projectsImportExportPartialRoute, configurationPartialRoute, projectBranchPartialRoute, projectPartialRoute, projectsPartialRoute, useQueryStringParams } from "@octopusdeploy/portal-routes";
import type { ReactElement } from "react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import type { RouteProps } from "react-router-dom";
import { useLocation, Switch } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ConfigurationLayoutProps {
}
export interface ProjectLayoutProps {
    projectSlug: string;
    branchName: string | undefined;
    isNewlyCreatedProject?: string | null;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RedirectProjectIfNotSlugProps {
}
export type ReloadableRouteProps = RouteProps & {
    computedMatch?: {
        params: Record<string, string>;
    };
    doNotReloadWhenTheseKeysChange?: string[];
};
export interface RoutingComponents {
    ReloadableRoute: React.ComponentType<ReloadableRouteProps>;
    ConfigurationLayout: React.ComponentType<ConfigurationLayoutProps>;
    ProjectLayout: React.ComponentType<ProjectLayoutProps>;
    RedirectProjectIfNotSlug: React.ComponentType<RedirectProjectIfNotSlugProps>;
}
interface AllRoutesProps {
    allRegisteredRoutes: UnknownRegisteredRoute[];
    legacyRoutes: {
        configuration: React.ComponentType;
        projects: React.ComponentType;
        projectsImportExport: React.ComponentType;
        nonVcsProjectRoutes: React.ComponentType;
        vcsProjectRoutes: React.ComponentType;
        other: ReactElement[];
    };
    components: RoutingComponents;
}
export function AllPageRoutes({ allRegisteredRoutes, components, legacyRoutes }: AllRoutesProps) {
    const { ReloadableRoute, ConfigurationLayout, ProjectLayout, RedirectProjectIfNotSlug } = components;
    return (<Switch>
            <ReloadableRoute path={formatRoutePath(projectsPartialRoute.template)} render={() => (<ProjectsRoutes allRegisteredRoutes={allRegisteredRoutes} ReloadableRoute={ReloadableRoute} LegacyProjectsRoutes={legacyRoutes.projects} LegacyProjectsImportExportRoutes={legacyRoutes.projectsImportExport} LegacyNonVcsProjectRoutes={legacyRoutes.nonVcsProjectRoutes} LegacyVcsProjectRoutes={legacyRoutes.vcsProjectRoutes} ProjectLayout={ProjectLayout} RedirectProjectIfNotSlug={RedirectProjectIfNotSlug}/>)}/>

            <ReloadableRoute path={formatRoutePath(configurationPartialRoute.template)} render={() => <ConfigurationRoutes allRegisteredRoutes={allRegisteredRoutes} LegacyConfigurationRoutes={legacyRoutes.configuration} ConfigurationLayout={ConfigurationLayout} ReloadableRoute={ReloadableRoute}/>}/>

            {legacyRoutes.other}
        </Switch>);
}
interface ProjectsRoutesProps {
    allRegisteredRoutes: UnknownRegisteredRoute[];
    ReloadableRoute: React.ComponentType<ReloadableRouteProps>;
    ProjectLayout: React.ComponentType<ProjectLayoutProps>;
    LegacyProjectsRoutes: React.ComponentType;
    LegacyProjectsImportExportRoutes: React.ComponentType;
    LegacyNonVcsProjectRoutes: React.ComponentType;
    LegacyVcsProjectRoutes: React.ComponentType;
    RedirectProjectIfNotSlug: React.ComponentType<RedirectProjectIfNotSlugProps>;
}
const branchNameKey: Extract<keyof ParametersOfPartialRouteDefinition<typeof projectBranchPartialRoute>, "branchName"> = "branchName";
function ProjectsRoutes({ allRegisteredRoutes, ReloadableRoute, ProjectLayout, LegacyProjectsRoutes, LegacyProjectsImportExportRoutes, LegacyVcsProjectRoutes, LegacyNonVcsProjectRoutes, RedirectProjectIfNotSlug }: ProjectsRoutesProps) {
    const location = useLocation();
    return (<Switch>
            {renderPartialRouteChildren(projectsPartialRoute, { allRegisteredRoutes, ReloadableRoute })}

            <ReloadableRoute path={formatRoutePath(projectsImportExportPartialRoute.template)} render={() => (<Switch>
                        {renderPartialRouteChildren(projectsImportExportPartialRoute, { allRegisteredRoutes, ReloadableRoute })}
                        <LegacyProjectsImportExportRoutes />
                    </Switch>)}/>

            <ReloadableRoute path={formatRoutePath(projectPartialRoute.template)} render={() => (<RedirectProjectIfNotSlug>
                        <Switch>
                            <ReloadableRoute path={formatRoutePath(projectBranchPartialRoute.template)} doNotReloadWhenTheseKeysChange={[branchNameKey]} render={(routeProps) => (<ProjectLayout projectSlug={routeProps.match.params.projectSlug} branchName={decodeRouteParameter(routeProps.match.params.branchName)} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                        <Switch>
                                            {renderPartialRouteChildren(projectBranchPartialRoute, { allRegisteredRoutes, ReloadableRoute })}
                                            <LegacyVcsProjectRoutes />
                                        </Switch>
                                    </ProjectLayout>)}/>
                            <ReloadableRoute render={(routeProps) => (<ProjectLayout projectSlug={routeProps.match.params.projectSlug} branchName={undefined} isNewlyCreatedProject={new URLSearchParams(location.search).get("newlyCreatedProject")}>
                                        <Switch>
                                            {renderPartialRouteChildren(projectPartialRoute, { allRegisteredRoutes, ReloadableRoute })}
                                            <LegacyNonVcsProjectRoutes />;
                                        </Switch>
                                    </ProjectLayout>)}/>
                        </Switch>
                    </RedirectProjectIfNotSlug>)}/>

            <LegacyProjectsRoutes />
        </Switch>);
}
interface ConfigurationRoutesProps {
    allRegisteredRoutes: UnknownRegisteredRoute[];
    ReloadableRoute: React.ComponentType<ReloadableRouteProps>;
    ConfigurationLayout: React.ComponentType<ConfigurationLayoutProps>;
    LegacyConfigurationRoutes: React.ComponentType;
}
function ConfigurationRoutes({ allRegisteredRoutes, ReloadableRoute, ConfigurationLayout, LegacyConfigurationRoutes }: ConfigurationRoutesProps) {
    return (<ConfigurationLayout>
            <Switch>
                {renderPartialRouteChildren(configurationPartialRoute, { allRegisteredRoutes, ReloadableRoute })}
                <LegacyConfigurationRoutes />
            </Switch>
        </ConfigurationLayout>);
}
interface RenderPartialRouteChildrenData {
    allRegisteredRoutes: UnknownRegisteredRoute[];
    ReloadableRoute: React.ComponentType<ReloadableRouteProps>;
}
function renderPartialRouteChildren(parentPartialRoute: UnknownPartialRouteDefinition, { allRegisteredRoutes, ReloadableRoute }: RenderPartialRouteChildrenData) {
    return allRegisteredRoutes
        .filter((r) => r.route.parentPartialRoute === parentPartialRoute)
        .map((r) => {
        const routePath = formatRoutePath(r.route.template);
        return <ReloadableRoute key={routePath} path={routePath} exact={true} render={(props) => <PageFromRouteDefinition routeDefinition={r} routeProps={props}/>}/>;
    });
}
function PageFromRouteDefinition({ routeDefinition, routeProps }: {
    routeDefinition: UnknownRegisteredRoute;
    routeProps: RouteComponentProps;
}) {
    const [queryParams, setQueryParams] = useQueryStringParams(routeDefinition.route.queryParameters);
    return routeDefinition.render(routeProps.match.params, queryParams, setQueryParams);
}
function formatRoutePath<T>(routeTemplate: RouteTemplate<T>) {
    return routeTemplate.map((part) => (typeof part === "string" ? part : isRouteParameter(part) ? formatRouteParameter(part) : optionalSpaceIdForSystemRoutes)).join("");
}
const optionalSpaceIdForSystemRoutes = "/:spaceId(Spaces-[0-9]+)?";
function formatRouteParameter<T extends string>(routeParameter: RequiredRouteParameter<T>) {
    // React router route parameters are prefixed with a ":"
    return `:${routeParameter.name}`;
}
function decodeRouteParameter(routeParameter: string | undefined): string | undefined {
    return routeParameter ? decodeURIComponent(routeParameter) : undefined;
}
