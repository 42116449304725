import type { UnknownRegisteredRoute } from "../routes/RegisteredRoute";
import type { PageUrl } from "./PageUrl";
import type { PartialRouteDefinition, UnknownPartialRouteDefinition } from "./PartialRouteDefinition";
import type { RouteTemplate } from "./RouteTemplate";
import { isRouteParameter, routeTemplate } from "./RouteTemplate";
import type { ParsedQueryParams, UnknownQueryParam } from "./query/QueryStringParam";
export interface PageRouteDefinition<RouteParameters, QueryParameters> {
    template: RouteTemplate<RouteParameters>;
    queryParameters: QueryParameters;
    parentPartialRoute: UnknownPartialRouteDefinition;
    generateUrl: OptionalParamsIfEmpty<(routeParams: RouteParameters, queryParams?: ParsedQueryParams<QueryParameters>) => PageUrl>;
    pageId: string;
    pageArea?: string;
    pageName: string;
}
// A common case is a route with no parameters. It is more ergonomic to omit the params object in this scenario
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OptionalParamsIfEmpty<T> = T extends (routeParams: infer RP, queryParams?: infer QP) => PageUrl ? ({} extends RP ? (queryParams?: QP) => PageUrl : T) : T;
interface CreatePageRouteOptions {
    pageId: string;
    pageArea?: string;
    pageName: string;
}
export function createPageRoute<RouteParameters, ParentRouteParameters, QueryParams extends UnknownQueryParam[]>(template: RouteTemplate<RouteParameters>, parentPartialRoute: PartialRouteDefinition<ParentRouteParameters>, queryParamDefinitions: [
    ...QueryParams
], { pageId, pageArea, pageName }: CreatePageRouteOptions): PageRouteDefinition<RouteParameters & ParentRouteParameters, QueryParams> {
    const combinedRouteTemplate = routeTemplate `${parentPartialRoute.template}${template}`;
    const routeDefinition: PageRouteDefinition<RouteParameters & ParentRouteParameters, QueryParams> = {
        template: combinedRouteTemplate,
        generateUrl,
        queryParameters: queryParamDefinitions,
        parentPartialRoute,
        pageId,
        pageArea,
        pageName,
    };
    return routeDefinition;
    function generateUrl(routeParams: RouteParameters & ParentRouteParameters, queryParams?: ParsedQueryParams<QueryParams>): PageUrl;
    function generateUrl(queryParams?: ParsedQueryParams<QueryParams>): PageUrl;
    function generateUrl(routeParamsOrQueryParams?: (RouteParameters & ParentRouteParameters) | ParsedQueryParams<QueryParams>, queryParams?: ParsedQueryParams<QueryParams>): PageUrl {
        if (isRouteParams(routeParamsOrQueryParams)) {
            return generateUrlInternal(routeParamsOrQueryParams, queryParams);
        }
        else {
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            return generateUrlInternal({} as RouteParameters & ParentRouteParameters, routeParamsOrQueryParams);
        }
    }
    function generateUrlInternal(routeParams: RouteParameters & ParentRouteParameters, queryParams?: ParsedQueryParams<QueryParams>) {
        return {
            resolveLegacyRoute(allRegisteredRoutes: UnknownRegisteredRoute[]) {
                const registeredRoute = allRegisteredRoutes.find((r) => r.route === routeDefinition);
                if (!registeredRoute) {
                    throw new Error(`No route registered for ${pageName}`);
                }
                return registeredRoute.legacyRouteLink(routeParams, queryParams);
            },
            resolveWithSpaceId(currentSpaceId: string | null) {
                return `${getRoute()}${getQueryString()}`;
                function getRoute() {
                    return combinedRouteTemplate
                        .map((part) => {
                        if (typeof part === "string")
                            return part;
                        if (isRouteParameter(part)) {
                            return routeParams[part.name];
                        }
                        if (currentSpaceId) {
                            return `/${currentSpaceId}`;
                        }
                        return "";
                    })
                        .join("");
                }
                function getQueryString() {
                    return generateQueryString(queryParamDefinitions, queryParams);
                }
            },
        };
    }
    function isRouteParams(routeParamsOrQueryParams?: (RouteParameters & ParentRouteParameters) | ParsedQueryParams<QueryParams>): routeParamsOrQueryParams is RouteParameters & ParentRouteParameters {
        return combinedRouteTemplate.some((exp) => typeof exp !== "string" && exp.type === "parameter");
    }
}
export function generateQueryString<QueryParams extends UnknownQueryParam[]>(queryParameter: [
    ...QueryParams
], queryValues?: ParsedQueryParams<QueryParams>): string {
    const queryParameterStrings = queryParameter.map((param) => {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const name = param.name as keyof typeof queryValues;
        if (queryValues === undefined || !queryValues.hasOwnProperty(name)) {
            return null;
        }
        const queryValue = queryValues[name];
        const serializedValue = param.serialize(queryValue);
        return `${param.name}=${serializedValue}`;
    });
    const nonEmptyStrings = queryParameterStrings.filter((s): s is string => s !== null);
    if (nonEmptyStrings.length > 0) {
        // Sorting keeps things more stable and deterministic
        return `?${nonEmptyStrings.sort().join("&")}`;
    }
    return "";
}
