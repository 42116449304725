import { createPageRoute } from "./PageRouteDefinition";
import { createPartialRoute } from "./PartialRouteDefinition";
import { routeTemplate } from "./RouteTemplate";
import type { RouteTemplate } from "./RouteTemplate";
import type { UnknownQueryParam } from "./query/QueryStringParam";
import { systemPartialRoute } from "./systemRoutes";
interface CreateConfigurationRouteOptions {
    pageName: string;
}
export const configurationPartialRoute = createPartialRoute(routeTemplate `${systemPartialRoute.template}/configuration`);
function createConfigurationRoute<RouteParams, QueryParams extends UnknownQueryParam[]>(template: RouteTemplate<RouteParams>, queryParams: [
    ...QueryParams
], { pageName }: CreateConfigurationRouteOptions) {
    return createPageRoute(template, configurationPartialRoute, queryParams, {
        pageArea: "Configuration",
        pageName,
        pageId: `Configuration.${pageName}`,
    });
}
export const licenseRoute = createConfigurationRoute(routeTemplate `/license`, [], { pageName: "License" });
export const gitRoute = createConfigurationRoute(routeTemplate `/git`, [], { pageName: "Git" });
