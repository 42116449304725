import type { UnknownRegisteredRoute } from "@octopusdeploy/portal-routes";
import React, { useContext } from "react";
const RegisteredRouteContext = React.createContext<UnknownRegisteredRoute[] | undefined>(undefined);
export const RegisteredRouteProvider = RegisteredRouteContext.Provider;
export function useRegisteredRoutes() {
    const registeredRoutes = useContext(RegisteredRouteContext);
    if (!registeredRoutes) {
        throw new Error("Context value has not been provided via a RegisteredRouteContext provider");
    }
    return registeredRoutes;
}
