import { css, cx } from "@emotion/css";
import { borderRadius, space, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { resetStyles } from "../../utils/resetStyles";
import type { ButtonImportance } from "./Button";
export function getButtonStyles(variant: ButtonImportance) {
    const buttonVariantStyles = getButtonVariantStyles(variant);
    return cx([baseButtonStyles, buttonVariantStyles]);
}
const baseButtonStyles = css({
    ...resetStyles.button,
    font: text.button.default.base,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",
    borderRadius: borderRadius.medium,
    paddingInlineStart: space[12],
    paddingInlineEnd: space[12],
    whiteSpace: "nowrap",
    boxSizing: "border-box",
});
function getButtonVariantStyles(variant: ButtonImportance): string {
    const variantLookup = getVariantLookupValue(variant);
    const backgroundTokens = themeTokens.color.button.background[variantLookup];
    const borderTokens = getBorderTokens(variantLookup);
    const textTokens = themeTokens.color.button.text[variantLookup];
    const iconTokens = themeTokens.color.button.icon[variantLookup];
    return css({
        color: textTokens.default,
        background: backgroundTokens.default,
        border: borderTokens ? `solid 1px ${borderTokens.default}` : undefined,
        fill: iconTokens.default,
        height: "2.5rem",
        boxSizing: "border-box",
        lineHeight: "1.5rem",
        font: text.button.default.base,
        ":hover": {
            background: backgroundTokens.hover,
        },
        ":focus-visible": {
            background: backgroundTokens.hover,
        },
        ":active": {
            background: backgroundTokens.pressed,
        },
        ":disabled": {
            color: textTokens.disabled,
            background: backgroundTokens.disabled,
            borderColor: borderTokens ? borderTokens.disabled : undefined,
            fill: iconTokens.disabled,
            cursor: "not-allowed",
        },
    });
}
function getBorderTokens(buttonVariant: ButtonImportance) {
    switch (buttonVariant) {
        case "primary":
            return undefined;
        case "secondary":
            return themeTokens.color.button.border.secondary;
        case "tertiary":
            return themeTokens.color.button.border.tertiary;
        case "destructive":
            return undefined;
        case "quiet":
            return undefined;
        default:
            exhaustiveCheck(buttonVariant, `Could not find lookup value for unknown variant: ${buttonVariant}`);
    }
}
function getVariantLookupValue(buttonVariant: ButtonImportance): keyof typeof themeTokens.color.button.background {
    switch (buttonVariant) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        case "tertiary":
            return "tertiary";
        case "destructive":
            return "destructive";
        case "quiet":
            return "quiet";
        default:
            exhaustiveCheck(buttonVariant, `Could not find lookup value for unknown variant: ${buttonVariant}`);
    }
}
