import { createPageRoute } from "./PageRouteDefinition";
import { createPartialRoute } from "./PartialRouteDefinition";
import { routeParam, routeTemplate } from "./RouteTemplate";
import { stringQueryParam } from "./query/QueryStringParam";
import { spacePartialRoute } from "./spaceRoutes";
export const projectsPartialRoute = createPartialRoute(routeTemplate `${spacePartialRoute.template}/projects`);
export const projectsImportExportPartialRoute = createPartialRoute(routeTemplate `${spacePartialRoute.template}/projects/importExport`);
export const projectPartialRoute = createPartialRoute(routeTemplate `${projectsPartialRoute.template}/${routeParam("projectSlug")}`);
export const projectBranchPartialRoute = createPartialRoute(routeTemplate `${projectPartialRoute.template}/branches/${routeParam("branchName")}`);
export const projectsRoute = createPageRoute(routeTemplate ``, projectsPartialRoute, [stringQueryParam("projectGroupId"), stringQueryParam("projectName"), stringQueryParam("newProjectGroupId")], {
    pageArea: "Project",
    pageName: "Projects",
    pageId: "Projects",
});
export const projectTasksRoute = createPageRoute(routeTemplate `/tasks`, projectPartialRoute, [], { pageArea: "Project", pageId: "Project.Tasks", pageName: "Tasks" });
