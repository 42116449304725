/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ResourceWithSlug } from "./ResourceWithSlug";
import type { ActionTemplateParameterResource } from "./actionTemplateParameterResource";
import type { DashboardItemResource } from "./dashboardItemResource";
import type { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import type { MetadataTypeCollection } from "./dynamicFormResources";
import type { EnvironmentResource } from "./environmentResource";
import type { ExtensionSettingsValues } from "./extensionSettingsValues";
import type { IconResource } from "./iconResource";
import type { NamedResource } from "./namedResource";
import type { SensitiveValue } from "./propertyValueResource";
import type { IIdName, ResourceWithLinks } from "./resource";
import type { RunbooksDashboardItemResource } from "./runbooksDashboardItemResource";
import type { NewSpaceScopedResource, SpaceScopedResource } from "./spaceScopedResource";
import type { TenantedDeploymentMode } from "./tenantedDeploymentMode";
import type { GitRef } from "./versionControlledResource";
import { isGitBranch, isGitCommit, isGitTag } from "./versionControlledResource";
interface BaseProjectResourceLinks {
    Self: string;
    Releases: string;
    Channels: string;
    Triggers: string;
    ScheduledTriggers: string;
    OrderChannels: string;
    Variables: string;
    Progression: string;
    RunbookTaskRunDashboardItemsTemplate: string;
    DeploymentProcess: string;
    Web: string;
    Logo: string;
    Metadata: string;
    Runbooks: string;
    RunbookSnapshots: string;
    Summary: string;
    DeploymentSettings: string;
    InsightsMetrics: string;
}
type VcsProjectResourceLinks = BaseProjectResourceLinks & {
    Branches: string;
    Commits: string;
    Tags: string;
    MigrateVariablesToGit: string;
};
type DatabaseProjectResourceLinks = BaseProjectResourceLinks & {
    ConvertToGit: string;
};
type ProjectResourceLinks = VcsProjectResourceLinks | DatabaseProjectResourceLinks;
export enum PersistenceSettingsType {
    VersionControlled = "VersionControlled",
    Database = "Database"
}
interface DatabasePersistenceSettings {
    Type: PersistenceSettingsType.Database;
}
export interface GitPersistenceSettings {
    Type: PersistenceSettingsType.VersionControlled;
    Credentials: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials;
    ConversionState: ConversionState;
    Url: string;
    DefaultBranch: string;
    BasePath: string;
    ProtectedDefaultBranch: boolean;
    ProtectedBranchNamePatterns: string[];
}
export interface DynamicEnvironmentSettings {
    ProvisioningRunbook?: string;
    DeprovisioningRunbook?: string;
}
export enum AuthenticationType {
    Anonymous = "Anonymous",
    UsernamePassword = "UsernamePassword",
    Reference = "Reference"
}
export interface ReferenceVcsCredentials {
    Type: AuthenticationType.Reference;
    Id: string;
}
export interface UsernamePasswordVcsCredentials {
    Type: AuthenticationType.UsernamePassword;
    Username: string;
    Password: SensitiveValue;
}
export interface AnonymousVcsCredentials {
    Type: AuthenticationType.Anonymous;
}
export interface ConversionState {
    VariablesAreInGit: boolean;
}
export function IsUsingUsernamePasswordAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is UsernamePasswordVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as UsernamePasswordVcsCredentials).Type === AuthenticationType.UsernamePassword;
}
export function IsUsingAnonymousAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is AnonymousVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as AnonymousVcsCredentials).Type === AuthenticationType.Anonymous;
}
export function IsUsingReferenceAuth(T: AnonymousVcsCredentials | UsernamePasswordVcsCredentials | ReferenceVcsCredentials): T is ReferenceVcsCredentials {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (T as ReferenceVcsCredentials).Type === AuthenticationType.Reference;
}
export function HasVariablesInGit(T: GitPersistenceSettings | DatabasePersistenceSettings): T is GitPersistenceSettings {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return HasGitPersistenceSettings(T) && T.ConversionState?.VariablesAreInGit;
}
export function HasVcsProjectResourceLinks(links: ProjectResourceLinks): links is VcsProjectResourceLinks {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (links as VcsProjectResourceLinks).Branches !== undefined;
}
export function HasGitPersistenceSettings(T: GitPersistenceSettings | DatabasePersistenceSettings): T is GitPersistenceSettings {
    return T.Type === PersistenceSettingsType.VersionControlled;
}
export interface ProjectResource extends NamedResource<ProjectResourceLinks>, SpaceScopedResource, ResourceWithSlug {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    TenantedDeploymentMode: TenantedDeploymentMode;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    ForcePackageDownload: boolean;
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ClonedFromProjectId: string;
    ExtensionSettings: ExtensionSettingsValues[];
    IsVersionControlled: boolean;
    PersistenceSettings: GitPersistenceSettings | DatabasePersistenceSettings;
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
    Icon?: IconResource;
}
export interface GetAllProjectSummariesResponse {
    Projects: ProjectSummaryResource[];
}
export interface ProjectSummaryResource extends IIdName, SpaceScopedResource {
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
    Logo: string;
}
export interface NewProjectResource extends NewSpaceScopedResource {
    Name: string;
    Description: string;
    ProjectGroupId: string;
    LifecycleId: string;
    ProjectConnectivityPolicy: {
        AllowDeploymentsToNoTargets: boolean;
    };
}
export interface ProjectSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}
export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
    ReleaseCreationPackageStepId?: string;
}
export interface VersionControlCompatibilityResponse {
    Errors: string[];
    Warnings: string[];
    Notices: string[];
}
export enum ValidateGitRefV2ResponseType {
    Success = "Success",
    ProjectNotVersionControlled = "ProjectNotVersionControlled",
    ConnectionFailed = "ConnectionFailed",
    GitRefNotFound = "GitRefNotFound",
    OclNotFound = "OclNotFound"
}
export type ValidateGitRefV2Response = {
    Type: ValidateGitRefV2ResponseType;
    GitRef?: GitRefResource;
    Message?: string;
};
export type ConvertProjectToVersionControlledResponse = {};
export interface RecentlyViewedProjectIds {
    [key: string]: string[];
}
export interface ProjectSummary {
    HasRunbooks: boolean;
    HasDeploymentProcess: boolean;
}
type GitRefResourceLinks = {
    DeploymentProcess: string;
    DeploymentSettings: string;
    ReleaseTemplate: string;
    Channels: string;
    Variables: string;
};
export interface GitRefResource extends ResourceWithLinks<GitRefResourceLinks> {
    Name: string;
    CanonicalName: GitRef;
}
export type GitBranchResource = GitRefResource & {
    IsProtected: boolean;
};
export type GitTagResource = GitRefResource;
export type GitCommitResource = GitRefResource;
export type GitLinkResult = {
    Link: string | undefined;
};
export function isGitBranchResource(gitRef: GitRefResource): gitRef is GitBranchResource {
    return isGitBranch(gitRef.CanonicalName);
}
export function getURISafeGitRef(gitRef: GitRef): string {
    return encodeURIComponent(gitRef);
}
export function getGitRefFromRouteParameter(routeParameter: string | undefined): string | undefined {
    if (routeParameter) {
        return decodeURIComponent(routeParameter);
    }
}
export interface ProjectStatusResponse {
    HasEnvironments: boolean;
    HasSteps: boolean;
    HasBeenSuccessfullyDeployed: boolean;
    HasRunbooks: boolean;
    HasSuccessfulDeploymentsInSpace: boolean;
    IsVersionControlled: boolean;
}
export interface ProjectDynamicEnvironmentOverviewResponse {
    DynamicEnvironmentOverviews: ProjectDynamicEnvironmentOverview[];
}
export type ProjectDynamicEnvironmentOverview = {
    EnvironmentName: string;
    EnvironmentId: string;
    LatestDeployment?: DashboardItemResource;
    ProvisioningRunbookRun?: RunbooksDashboardItemResource;
    DeprovisioningRunbookRun?: RunbooksDashboardItemResource;
};
export interface DeploymentSummary {
    Id: string;
    Status: string;
    CompletedTime: string;
}
export interface ProjectIntentsResource {
    SpaceId: string;
    ProjectId: string;
    ProjectGroupId?: string;
    IsVcsProject?: boolean;
    ApplicationType?: string;
    BuildServer?: string;
    DeploymentLocation?: string;
    PackageLocation?: string;
    RunbookOnly?: boolean;
}
export interface GetAvailableEnvironmentsForProjectBffResponse {
    Environments: EnvironmentResource[];
}
export interface CreateSampleProjectBffResponse {
    Project: ProjectResource;
}
export function isProtectedBranch(gitRef: GitRefResource | undefined) {
    if (!gitRef)
        return false;
    if (isGitBranchResource(gitRef)) {
        return gitRef.IsProtected;
    }
    return false;
}
export function canCommitTo(gitRef: GitRefResource | undefined) {
    if (!gitRef)
        return false;
    if (isProtectedBranch(gitRef))
        return false;
    if (isGitTag(gitRef.CanonicalName))
        return false;
    if (isGitCommit(gitRef.CanonicalName))
        return false;
    return true;
}
