import { css } from "@emotion/css";
import { Divider as MuiDivider } from "@material-ui/core";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export interface DividerProps {
    orientation?: "horizontal" | "vertical";
}
export function Divider({ orientation }: DividerProps) {
    return <MuiDivider classes={{ root: dividerStyles }} orientation={orientation}/>;
}
const dividerClasses = {
    root: "MuiDivider-root",
};
const dividerStyles = css({
    [`&.${dividerClasses.root}`]: {
        backgroundColor: themeTokens.color.border.primary,
    },
});
