import { css } from "@emotion/css";
import MaterialIconButton from "@material-ui/core/IconButton";
import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import Add from "@material-ui/icons/Add";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import Cancel from "@material-ui/icons/Clear";
import UseDefaultImage from "@material-ui/icons/CropOriginal";
import Remove from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import CopyToClipboard from "@material-ui/icons/FileCopyOutlined";
import EnterFullScreen from "@material-ui/icons/Fullscreen";
import ExitFullScreen from "@material-ui/icons/FullscreenExit";
import HelpIcon from "@material-ui/icons/HelpOutline";
import Expand from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/icons/KeyboardArrowUp";
import OverflowMenu from "@material-ui/icons/MoreVert";
import Upload from "@material-ui/icons/Publish";
import Refresh from "@material-ui/icons/Refresh";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import Share from "@material-ui/icons/Share";
import Show from "@material-ui/icons/Visibility";
import Hide from "@material-ui/icons/VisibilityOff";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import type { AriaAttributes, ComponentType } from "react";
import React, { useImperativeHandle, useRef } from "react";
import { BindIcon as Bind } from "./Icons/BindIcon";
import { ChevronIcon } from "./Icons/ChevronIcon";
import { InsertVariableIcon as InsertVariable } from "./Icons/InsertVariableIcon";
import { SelectLogoIcon as SelectLogo } from "./Icons/SelectLogoIcon";
import { UnbindIcon as Unbind } from "./Icons/UnbindIcon";
export interface IconButtonElement {
    focus: () => void;
}
export const IconButtonIcons = [
    "Add",
    "ArrowDown",
    "ArrowUp",
    "Cancel",
    "UseDefaultImage",
    "Remove",
    "Edit",
    "CopyToClipboard",
    "EnterFullScreen",
    "ExitFullScreen",
    "OpenHelp",
    "CloseHelp",
    "Expand",
    "Collapse",
    "OverflowMenu",
    "Upload",
    "Refresh",
    "Search",
    "Show",
    "Hide",
    "Bind",
    "Unbind",
    "ChevronIcon",
    "InsertVariable",
    "SelectLogo",
    "SaveAlt",
    "Share",
] as const;
export type IconButtonIconType = (typeof IconButtonIcons)[number];
const Icon: Record<IconButtonIconType, ComponentType<SvgIconProps>> = {
    Add,
    ArrowDown,
    ArrowUp,
    Cancel,
    UseDefaultImage,
    Remove,
    Edit,
    CopyToClipboard,
    EnterFullScreen,
    ExitFullScreen,
    OpenHelp: HelpIcon,
    CloseHelp: HelpIcon,
    Expand,
    Collapse,
    OverflowMenu,
    Upload,
    Refresh,
    Search,
    Show,
    Hide,
    Bind,
    Unbind,
    ChevronIcon,
    InsertVariable,
    SelectLogo,
    SaveAlt,
    Share,
};
interface SharedButtonProps extends SupportedAriaAttributes {
    /**
     * The classname to apply to the icon button.
     *
     * @deprecated Please avoid using this. If you need to add margin / other layout concerns
     * use a wrapping element instead. This property will be removed in the near future.
     */
    className?: string;
    /**
     * The style to apply to the icon button.
     *
     * @deprecated Please avoid using this. If you need to add margin / other layout concerns
     * use a wrapping element instead. This property will be removed in the near future.
     */
    style?: React.CSSProperties;
    /**
     * A property to control whether the icon button is enabled.
     */
    disabled?: boolean;
    /**
     * A property passed to MUI to control ripple effects.
     */
    disableRipple?: boolean;
    /**
     * The action to perform when the icon button is clicked.
     */
    onClick?: (event: React.MouseEvent) => void;
    /**
     * The accessible name to use for the icon button
     *
     * All icon buttons should have an accessible name as it simply consists of an icon and no label for the accessibility tree.
     * If a predefined icon is used and no accessible name is provided, then the icon name is used for the accessible name.
     */
    accessibleName?: string;
    /**
     * The tabIndex for the IconButton
     *
     * Navigating to the button using Tab can be disabled by providing a value of -1. Any other value for tabIndex should be
     * avoided if possible.
     */
    tabIndex?: number;
}
interface SupportedAriaAttributes {
    /**
     * Indicates whether the icon button is currently expanded or collapsed.
     */
    "aria-expanded"?: AriaAttributes["aria-expanded"];
    /**
     * Identifies the element (or elements) whose contents or presence are controlled by
     * this icon button.
     */
    "aria-controls"?: AriaAttributes["aria-controls"];
    /**
     * Indicates the availability and type of interactive poup element, such as menu or dialog,
     * that can be triggered by this icon button.
     */
    "aria-haspopup"?: AriaAttributes["aria-haspopup"];
}
interface CustomButtonIconProps extends SharedButtonProps {
    /**
     * A custom react element to use for the icon.
     *
     * A custom react elements need to ensure they set the appropriate color
     * for the icon. For all standard icons that we provide, this isn't necessary,
     * however, if you have a custom svg you will need the fill to be set to
     * `currentColor` for the icon to use the correct color.
     *
     * @example
     * <IconButton customIcon={<MyCustomIcon />} onClick={clickHandler} />
     */
    customIcon: React.ReactElement;
}
interface PredefinedButtonIconProps extends SharedButtonProps {
    /**
     * The predefined icon to use as part of the icon button.
     *
     * @example
     * <IconButton icon={"Cancel"} onClick={clickHandler} />
     */
    icon: IconButtonIconType;
}
export type IconButtonProps = CustomButtonIconProps | PredefinedButtonIconProps;
export const IconButton = React.forwardRef<IconButtonElement, IconButtonProps>((props, ref) => {
    const accessibleName = !props.accessibleName && isPredefinedButtonIconProps(props) ? props.icon : props.accessibleName;
    const icon = isPredefinedButtonIconProps(props) ? <PredefinedIcon icon={props.icon}/> : props.customIcon;
    return (<StyledMaterialIconButton icon={icon} accessibleName={accessibleName} className={props.className} ref={ref} style={props.style} onClick={props.onClick} disabled={props.disabled} disableRipple={props.disableRipple} tabIndex={props.tabIndex} aria-expanded={props["aria-expanded"]} aria-controls={props["aria-controls"]} aria-haspopup={props["aria-haspopup"]}/>);
});
IconButton.displayName = "IconButton";
function isPredefinedButtonIconProps(props: IconButtonProps): props is PredefinedButtonIconProps {
    return "icon" in props && isPredefinedIcon(props.icon);
}
export function isPredefinedIcon(icon: string): icon is IconButtonIconType {
    return icon in Icon;
}
function PredefinedIcon({ icon }: {
    icon: IconButtonIconType;
}) {
    const ResolvedIcon = Icon[icon];
    return <ResolvedIcon />;
}
PredefinedIcon.displayName = "PredefinedIcon";
interface StyledMaterialIconButtonProps extends SharedButtonProps {
    icon: React.ReactElement | string;
}
const StyledMaterialIconButton = React.forwardRef<IconButtonElement, StyledMaterialIconButtonProps>(({ disabled, tabIndex, icon, onClick, accessibleName, disableRipple, style, className, "aria-expanded": ariaExpanded, "aria-controls": ariaControls, "aria-haspopup": ariaHasPopup, children }, ref) => {
    const iconButtonRef = useRef<HTMLButtonElement | null>(null);
    useImperativeHandle(ref, () => ({
        focus: () => iconButtonRef.current?.focus(),
    }));
    return (<MaterialIconButton className={className} classes={{ root: iconButtonRootStyles }} disabled={disabled} disableRipple={disableRipple} tabIndex={tabIndex} onClick={onClick} ref={iconButtonRef} style={style} aria-label={accessibleName} aria-expanded={ariaExpanded} aria-controls={ariaControls} aria-haspopup={ariaHasPopup}>
                {icon}
            </MaterialIconButton>);
});
StyledMaterialIconButton.displayName = "StyledMaterialIconButton";
const iconButtonClasses = {
    root: "MuiIconButton-root",
};
const iconButtonRootStyles = css({
    [`&.${iconButtonClasses.root}`]: {
        padding: 0,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        color: themeTokens.color.button.icon.secondary.default,
        "&:hover": {
            color: themeTokens.color.button.icon.secondary.hover,
        },
    },
});
export default IconButton;
