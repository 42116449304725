import { css } from "@emotion/css";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
import { iconStyles } from "./iconStyles";
export function GitBranchIcon() {
    return (<svg className={styles} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 8C4.02516 8 4.9062 7.38295 5.29198 6.5H7C8.10457 6.5 9 7.39543 9 8.5V11.5C9 13.7091 10.7909 15.5 13 15.5H14.708C15.0938 16.383 15.9748 17 17 17C18.3807 17 19.5 15.8807 19.5 14.5C19.5 13.1193 18.3807 12 17 12C15.9748 12 15.0938 12.617 14.708 13.5H13C11.8954 13.5 11 12.6046 11 11.5V8.5C11 6.29086 9.20914 4.5 7 4.5H5.29198C4.9062 3.61705 4.02516 3 3 3C1.61929 3 0.5 4.11929 0.5 5.5C0.5 6.88071 1.61929 8 3 8ZM3 6.25C3.41421 6.25 3.75 5.91421 3.75 5.5C3.75 5.08579 3.41421 4.75 3 4.75C2.58579 4.75 2.25 5.08579 2.25 5.5C2.25 5.91421 2.58579 6.25 3 6.25ZM17.75 14.5C17.75 14.9142 17.4142 15.25 17 15.25C16.5858 15.25 16.25 14.9142 16.25 14.5C16.25 14.0858 16.5858 13.75 17 13.75C17.4142 13.75 17.75 14.0858 17.75 14.5Z"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M17 8C18.3807 8 19.5 6.88071 19.5 5.5C19.5 4.11929 18.3807 3 17 3C15.9748 3 15.0938 3.61705 14.708 4.5L12.75 4.5C12.1977 4.5 11.75 4.94772 11.75 5.5C11.75 6.05228 12.1977 6.5 12.75 6.5L14.708 6.5C15.0938 7.38295 15.9748 8 17 8ZM17 6.25C17.4142 6.25 17.75 5.91421 17.75 5.5C17.75 5.08579 17.4142 4.75 17 4.75C16.5858 4.75 16.25 5.08579 16.25 5.5C16.25 5.91421 16.5858 6.25 17 6.25Z"/>
        </svg>);
}
const styles = css(iconStyles, {
    width: 20,
    height: 20,
    fill: themeTokens.color.icon.subtle,
});
