export const colorScales = {
    "white": "#FFFFFF",
    "black": "#000000",
    "blue": {
        "100": "#F2F8FD",
        "200": "#CDE4F7",
        "300": "#87BFEC",
        "400": "#449BE1",
        "500": "#1A77CA",
        "600": "#155EA0",
        "700": "#0F4778",
        "800": "#093051",
        "900": "#041A2D"
    },
    "green": {
        "100": "#EEFAF5",
        "200": "#B8E7D3",
        "300": "#5ECD9E",
        "400": "#00AB62",
        "500": "#00874D",
        "600": "#006A3D",
        "700": "#00502E",
        "800": "#00361F",
        "900": "#001E11"
    },
    "purple": {
        "100": "#F8F5FD",
        "200": "#E7DEF8",
        "300": "#C5AEEE",
        "400": "#A683E5",
        "500": "#895ED3",
        "600": "#7043B7",
        "700": "#572B97",
        "800": "#3B1D66",
        "900": "#21103A"
    },
    "orange": {
        "100": "#FFF5ED",
        "200": "#FFDABF",
        "300": "#FFA461",
        "400": "#EA7325",
        "500": "#C45317",
        "600": "#A13C14",
        "700": "#7E2812",
        "800": "#59170D",
        "900": "#340B07"
    },
    "yellow": {
        "100": "#FFF7D9",
        "200": "#FFDF62",
        "300": "#E5B203",
        "400": "#B98F02",
        "500": "#927002",
        "600": "#745801",
        "700": "#584201",
        "800": "#3B2C00",
        "900": "#201800"
    },
    "red": {
        "100": "#FFF3F3",
        "200": "#FFD8D8",
        "300": "#FF9F9F",
        "400": "#FF5D5D",
        "500": "#D63D3D",
        "600": "#AA3030",
        "700": "#802424",
        "800": "#571818",
        "900": "#310E0E"
    },
    "navy": {
        "100": "#F4F6F8",
        "200": "#DAE2E9",
        "300": "#A9BBCB",
        "400": "#7C98B4",
        "500": "#557999",
        "600": "#3E607D",
        "700": "#2E475D",
        "800": "#1F303F",
        "900": "#111A23"
    },
    "slate": {
        "100": "#F5F6F8",
        "200": "#DEE1E6",
        "300": "#B2B9C5",
        "400": "#8A96A7",
        "500": "#68778D",
        "600": "#515D70",
        "700": "#3D4653",
        "800": "#282F38",
        "900": "#16191F"
    },
    "grey": {
        "100": "#F7F7F7",
        "200": "#E1E1E1",
        "300": "#B9B9B9",
        "400": "#959595",
        "500": "#757575",
        "600": "#5C5C5C",
        "700": "#454545",
        "800": "#2E2E2E",
        "900": "#191919"
    }
} as const;
export const space = {
    "1": "0.063rem",
    "2": "0.125rem",
    "4": "0.25rem",
    "8": "0.5rem",
    "12": "0.75rem",
    "16": "1rem",
    "24": "1.5rem",
    "32": "2rem",
    "40": "2.5rem",
    "48": "3rem",
    "56": "3.5rem",
    "64": "4rem",
    "72": "4.5rem",
    "80": "5rem"
} as const;
export const fontWeight = {
    "400": 400,
    "500": 500,
    "700": 700
} as const;
export const lineHeight = {
    "xSmall": "1.25rem",
    "small": "1.5rem",
    "medium": "1.75rem",
    "large": "2rem",
    "xLarge": "2.5rem",
    "2xLarge": "2.75rem"
} as const;
export const fontFamily = {
    "roboto": "Roboto",
    "roboto-mono": "Roboto Mono"
} as const;
export const fontSize = {
    "xSmall": "0.75rem",
    "small": "0.813rem",
    "medium": "0.875rem",
    "base": "1rem",
    "large": "1.25rem",
    "xLarge": "1.5rem",
    "2xLarge": "2rem"
} as const;
export const textDecoration = {
    "none": "none",
    "underline": "underline"
} as const;
export const textCase = {
    "none": "none",
    "uppercase": "uppercase",
    "lowercase": "lowercase"
} as const;
export const borderRadius = {
    "none": "0",
    "small": "2px",
    "medium": "4px",
    "large": "8px",
    "extraLarge": "16px",
    "circle": "9999px"
} as const;
export const borderWidth = {
    "1": "1px",
    "2": "2px",
    "3": "3px",
    "4": "4px",
    "none": "0"
} as const;
export const letterSpacing = {
    "none": "0",
    "tight": "-0.006rem",
    "wide": "0.006rem",
    "wider": "0.013rem"
} as const;
export const textScales = {
    "heading": {
        "large": {
            "normal": {
                "400": "400 2rem/2.75rem Roboto",
                "500": "500 2rem/2.75rem Roboto",
                "700": "700 2rem/2.75rem Roboto"
            },
            "tight": {
                "400": "400 2rem/2.5rem Roboto",
                "500": "500 2rem/2.5rem Roboto",
                "700": "700 2rem/2.5rem Roboto"
            }
        },
        "medium": {
            "normal": {
                "400": "400 1.5rem/2rem Roboto",
                "500": "500 1.5rem/2rem Roboto",
                "700": "700 1.5rem/2rem Roboto"
            },
            "tight": {
                "400": "400 1.5rem/1.75rem Roboto",
                "500": "500 1.5rem/1.75rem Roboto",
                "700": "700 1.5rem/1.75rem Roboto"
            }
        },
        "small": {
            "normal": {
                "400": "400 1.25rem/1.75rem Roboto",
                "500": "500 1.25rem/1.75rem Roboto",
                "700": "700 1.25rem/1.75rem Roboto"
            },
            "tight": {
                "400": "400 1.25rem/1.5rem Roboto",
                "500": "500 1.25rem/1.5rem Roboto",
                "700": "700 1.25rem/1.5rem Roboto"
            }
        }
    },
    "body": {
        "base": {
            "normal": {
                "400": "400 1rem/1.5rem Roboto",
                "500": "500 1rem/1.5rem Roboto",
                "700": "700 1rem/1.5rem Roboto"
            },
            "tight": {
                "400": "400 1rem/1.25rem Roboto",
                "500": "500 1rem/1.25rem Roboto",
                "700": "700 1rem/1.25rem Roboto"
            }
        },
        "medium": {
            "normal": {
                "400": "400 0.875rem/1.5rem Roboto",
                "500": "500 0.875rem/1.5rem Roboto",
                "700": "700 0.875rem/1.5rem Roboto"
            },
            "tight": {
                "400": "400 0.875rem/1.25rem Roboto",
                "500": "500 0.875rem/1.25rem Roboto",
                "700": "700 0.875rem/1.25rem Roboto"
            }
        },
        "small": {
            "normal": {
                "400": "400 0.813rem/1.25rem Roboto",
                "500": "500 0.813rem/1.25rem Roboto",
                "700": "700 0.813rem/1.25rem Roboto"
            }
        },
        "xSmall": {
            "normal": {
                "400": "400 0.75rem/1.25rem Roboto",
                "500": "500 0.75rem/1.25rem Roboto",
                "700": "700 0.75rem/1.25rem Roboto"
            }
        }
    }
} as const;
export const text = {
    "interface": {
        "heading": {
            "medium": "700 1.5rem/2rem Roboto",
            "small": "700 1.25rem/1.75rem Roboto"
        },
        "body": {
            "default": {
                "base": "400 1rem/1.5rem Roboto",
                "medium": "400 0.875rem/1.5rem Roboto",
                "small": "400 0.813rem/1.25rem Roboto",
                "xSmall": "400 0.75rem/1.25rem Roboto"
            },
            "bold": {
                "base": "700 1rem/1.5rem Roboto",
                "medium": "700 0.875rem/1.5rem Roboto",
                "small": "700 0.813rem/1.25rem Roboto",
                "xSmall": "700 0.75rem/1.25rem Roboto"
            }
        }
    },
    "button": {
        "default": {
            "base": "700 1rem/1.5rem Roboto",
            "medium": "700 0.875rem/1.5rem Roboto"
        },
        "quiet": {
            "base": "400 1rem/1.5rem Roboto",
            "medium": "400 0.875rem/1.5rem Roboto"
        }
    },
    "chip": {
        "small": "400 0.813rem/1.25rem Roboto"
    },
    "code": {
        "base": "400 1rem/1.5rem Roboto Mono",
        "medium": "400 0.875rem/1.5rem Roboto Mono"
    },
    "breadcrumb": {
        "xSmall": "400 0.75rem/1.25rem Roboto"
    },
    "table": {
        "cell": {
            "default": {
                "base": "400 1rem/1.5rem Roboto",
                "medium": "400 0.875rem/1.5rem Roboto",
                "xSmall": "400 0.75rem/1.25rem Roboto"
            },
            "bold": {
                "base": "700 1rem/1.5rem Roboto",
                "medium": "700 0.875rem/1.5rem Roboto",
                "xSmall": "700 0.75rem/1.25rem Roboto"
            }
        },
        "sort": {
            "xSmall": "700 0.75rem/1.25rem Roboto"
        }
    },
    "textField": {
        "inputField": "400 1rem/1.5rem Roboto",
        "inputLabel": "400 0.75rem/1.25rem Roboto",
        "smallText": "400 0.75rem/1.25rem Roboto"
    },
    "menuList": {
        "base": "400 1rem/1.5rem Roboto",
        "medium": "400 0.875rem/1.5rem Roboto",
        "small": "400 0.813rem/1.25rem Roboto"
    },
    "alert": {
        "heading": "700 1rem/1.5rem Roboto",
        "body": "400 0.875rem/1.25rem Roboto"
    },
    "navigation": {
        "button": {
            "medium": "700 0.875rem/1.5rem Roboto"
        },
        "link": {
            "medium": "400 0.875rem/1.5rem Roboto"
        }
    },
    "link": {
        "default": {
            "base": "400 1rem/1.5rem Roboto",
            "medium": "400 0.875rem/1.5rem Roboto",
            "small": "400 0.813rem/1.25rem Roboto",
            "xSmall": "400 0.75rem/1.25rem Roboto"
        },
        "bold": {
            "base": "700 1rem/1.5rem Roboto",
            "medium": "700 0.875rem/1.5rem Roboto",
            "small": "700 0.813rem/1.25rem Roboto",
            "xSmall": "700 0.75rem/1.25rem Roboto"
        }
    },
    "tooltip": {
        "xSmall": "400 0.75rem/1.25rem Roboto"
    }
} as const;
