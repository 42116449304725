import type { ReactElement } from "react";
import type { ParsedQueryParams } from "../routes/query/QueryStringParam";
import type { PageRouteDefinition } from "./PageRouteDefinition";
export interface RegisteredRoute<RouteParams, QueryParams> {
    route: PageRouteDefinition<RouteParams, QueryParams>;
    render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement;
    /*
    This function should return something from the routeLinks collection.
    It will be used to generate a URL for a Link when the routing feature toggle is disabled.
     */
    legacyRouteLink: (parameters: RouteParams, queryParams?: ParsedQueryParams<QueryParams>) => string;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownRegisteredRoute = RegisteredRoute<any, any>;
export function createRegisteredRoute<RouteParams, QueryParams>(route: PageRouteDefinition<RouteParams, QueryParams>, render: (parameters: RouteParams, queryParams: ParsedQueryParams<QueryParams>, setQueryParams: (queryParams: ParsedQueryParams<QueryParams>) => void) => ReactElement, legacyRouteLink: (parameters: RouteParams, queryParams?: ParsedQueryParams<QueryParams>) => string): RegisteredRoute<RouteParams, QueryParams> {
    return {
        route,
        render,
        legacyRouteLink,
    };
}
